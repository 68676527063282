import React, {useEffect} from 'react';
import './styles/style.css';
import './styles/responsive.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ReactGA from 'react-ga4';
import {useLocation} from 'react-router-dom';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    // Redirect only if the user is at the root of the site or specific paths
    if (
      location.pathname === '/' ||
      location.pathname === '/service-provider' ||
      location.pathname === '/blogs' ||
      location.pathname === '/invest'
    ) {
      window.location.href = 'https://ohmercanada.ca';
    }
  }, [location]);

  useEffect(() => {
    ReactGA.initialize('G-YNK5TY600R');
    AOS.init({
      duration: 1900,
    });
  }, []);

  return (
    <div className="App">
      {/* <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/service-provider" element={<ServiceProvider />} />
        <Route path="/invest" element={<Invest />} />
        <Route path="/blogs" element={<Blogs />} />
      </Routes>
      <div className="progress-wrap">
        <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div> */}
    </div>
  );
};

export default App;
